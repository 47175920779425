import { Tag as ChakraTag, TagCloseButton, TagLabel } from '@chakra-ui/react';
import { useDeleteTag } from 'hooks/tags';

export default function Tag({ tag }) {
  const { text } = tag;
  const { deleteTag, isLoading: deleteTagLoading } = useDeleteTag(tag.id);

  return (
    <ChakraTag
      size="md"
      key={text}
      borderRadius="full"
      variant="solid"
      colorScheme="teal"
      mx="2"
    >
      <TagLabel>{text}</TagLabel>
      {!deleteTagLoading ? <TagCloseButton onClick={deleteTag} /> : ''}
    </ChakraTag>
  );
}
