import { useEffect, useState } from 'react';

export function useUploads(authToken) {
  const [isLoading, setLoading] = useState(true);
  const [uploadUrl, setUploadUrl] = useState(null);
  const [uploadToken, setUploadToken] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);

      // !!! TODO: Shouldn't keep this here
      let url = 'https://uploads-nearspatial-net.nearspatial.net/';

      let res = await fetch(url, {
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache',
        headers: {
          Authorization: `Bearer ${authToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Near': 'foo'
        }
      });

      if (res.ok) {
        let resBody = await res.json();
        setUploadUrl(resBody.uploadUrl);
        setUploadToken(resBody.authorizationToken);
        setLoading(false);
      } else {
        setError(new Error(`Error fetching upload URL, status ${res.status}`));
      }
    }

    if (authToken) {
      fetchData();
    }
  }, [authToken]);

  return { uploadUrl, uploadToken, isLoading, error };
}
