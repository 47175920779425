import { Box, Flex, Input } from '@chakra-ui/react';
import NewInvite from './NewInvite';
import InviteList from './InviteList';
import { useState } from 'react';

export default function Invites() {
  const [filter, setFilter] = useState('');

  return (
    <Box align="center" pt="50" maxWidth="800px">
      <NewInvite />

      <Box flex="1" ml="4" mx="auto" maxW="800px">
        <Flex padding="4">
          <Input
            size="sm"
            padding="4"
            variant="flushed"
            placeholder="Filter invites"
            autoComplete="off"
            onChange={(ev) => setFilter(ev.target.value)}
          />
        </Flex>
      </Box>

      <InviteList email={filter} />
    </Box>
  );
}
