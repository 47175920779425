import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from 'lib/firebase';

export default async function doesUsernameOrEmailExist(username, email) {
  let q = query(collection(db, 'users'), where('username', '==', username));
  let querySnapshot = await getDocs(q);
  const usernameExists = querySnapshot.size > 0;

  q = query(collection(db, 'users'), where('email', '==', email));
  querySnapshot = await getDocs(q);
  const emailExists = querySnapshot.size > 0;

  return [usernameExists, emailExists];
}
