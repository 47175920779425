import { Flex, IconButton, Tooltip } from '@chakra-ui/react';
import { FaRegComment, FaComment, FaTrash, FaFilePen } from 'react-icons/fa6';
import { Link } from 'react-router-dom';

import { APP } from 'lib/routes';
import { useDeletePost } from 'hooks/posts';
import { useComments } from 'hooks/comments';
import { useAuth } from 'hooks/auth';

export default function Actions({ post }) {
  const { id, uid } = post;
  const { user: authUser, isLoading: authLoading } = useAuth();
  const { deletePost, isLoading: deleteLoading } = useDeletePost(id);
  const { comments, isLoading: commentsLoading } = useComments({ postId: id });

  if (!comments || deleteLoading || commentsLoading) return 'Loading...';

  return (
    <Flex p="2">
      <Flex alignItems="center">
        <IconButton
          as={Link}
          to={`${APP}/comments/${id}`}
          isLoading={commentsLoading}
          size="md"
          colorScheme="teal"
          variant="ghost"
          icon={comments.length > 0 ? <FaComment /> : <FaRegComment />}
          isRound
        />
        {comments.length}
      </Flex>

      {!authLoading && authUser.id === uid && deletePost ? (
        <Tooltip label="Delete post">
          <IconButton
            onClick={deletePost}
            isLoading={deleteLoading}
            marginLeft="auto"
            size="md"
            colorScheme="red"
            variant="ghost"
            icon={<FaTrash />}
            isRound
          />
        </Tooltip>
      ) : (
        ''
      )}
      {uid === authUser.id ? (
        <IconButton
          as={Link}
          to={`${APP}/post/${id}`}
          isLoading={false}
          size="md"
          colorScheme="teal"
          variant="ghost"
          icon={<FaFilePen />}
          isRound
        />
      ) : (
        ''
      )}
    </Flex>
  );
}
