import { createBrowserRouter } from 'react-router-dom';

import Login from 'components/auth/Login';
import Register from 'components/auth/Register';
import Dashboard from 'components/dashboard';
import Layout from 'components/layout';
import Comments from 'components/comments';
import Profile from 'components/profile';
import Users from 'components/users';
import NewPostStart from 'components/post/NewPostStart';
import EditPost from 'components/post/EditPost';
import Tags from 'components/tags';
import Invites from 'components/invites';

export const ROOT = '/';
export const APP = '/app';
export const APP_COMMENTS = '/app/comments/:id';
export const APP_DASHBOARD = '/app/dashboard';
export const APP_POST_NEW_START = '/app/post';
export const APP_POST_EDIT = '/app/post/:id';
export const APP_PROFILE = '/app/profile/:id';
export const APP_USERS = '/app/users';
export const ADMIN_INVITES = '/app/invites';
export const ADMIN_TAGS = '/app/tags';
export const LOGIN = '/login';
export const REGISTER = '/register';

export const router = createBrowserRouter([
  { path: ROOT, element: <Dashboard /> },
  { path: LOGIN, element: <Login /> },
  { path: REGISTER, element: <Register /> },
  {
    path: APP,
    element: <Layout />,
    children: [
      { path: APP_DASHBOARD, element: <Dashboard /> },
      { path: APP_USERS, element: <Users /> },
      { path: APP_POST_NEW_START, element: <NewPostStart /> },
      { path: APP_POST_EDIT, element: <EditPost /> },
      { path: APP_PROFILE, element: <Profile /> },
      { path: APP_COMMENTS, element: <Comments /> },
      { path: ADMIN_TAGS, element: <Tags /> },
      { path: ADMIN_INVITES, element: <Invites /> }
    ]
  }
]);
