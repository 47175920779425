import { Flex, HStack, IconButton, Tooltip } from '@chakra-ui/react';

import { FaBinoculars, FaDoorClosed, FaDoorOpen } from 'react-icons/fa6';
import { useDisableUser } from 'hooks/users';
import { ConnectionType } from 'lib/enums';
import { useAddConnection, useDeleteConnection } from 'hooks/connections';

export default function Actions({ user, authUser, isFollowed }) {
  const { id, isDisabled } = user;

  const { updateDisabled, isLoading: disableUserLoading } = useDisableUser(
    id,
    !isDisabled
  );
  const { addConnection: follow, isLoading: followLoading } = useAddConnection(
    authUser,
    user,
    ConnectionType.Follow
  );
  const { deleteConnection: unfollow, isLoading: unfollowLoading } =
    useDeleteConnection(authUser, user, ConnectionType.Follow);

  if (disableUserLoading || followLoading || unfollowLoading)
    return 'Loading...';

  return (
    <HStack>
      <Flex borderBottom="1px solid" borderColor="teal.100" pb="2">
        {user.id !== authUser.id ? (
          isFollowed ? (
            <Tooltip label="Unfollow">
              <IconButton
                size="md"
                ml="auto"
                icon={<FaBinoculars />}
                colorScheme="red"
                isRound
                onClick={unfollow}
                isLoading={unfollowLoading}
              />
            </Tooltip>
          ) : (
            <Tooltip label="Follow">
              <IconButton
                size="md"
                ml="auto"
                icon={<FaBinoculars />}
                colorScheme="teal"
                variant="ghost"
                isRound
                onClick={follow}
                isLoading={followLoading}
              />
            </Tooltip>
          )
        ) : (
          ''
        )}
      </Flex>

      <Flex borderBottom="1px solid" borderColor="teal.100" pb="2">
        {user.id !== authUser.id ? (
          authUser.isAdmin || authUser.isHosting ? (
            !isDisabled ? (
              <Tooltip label="Disable user">
                <IconButton
                  size="md"
                  ml="auto"
                  icon={<FaDoorClosed />}
                  colorScheme="red"
                  variant="ghost"
                  isRound
                  onClick={updateDisabled}
                  isLoading={disableUserLoading}
                />
              </Tooltip>
            ) : (
              <Tooltip label="Enable user">
                <IconButton
                  size="md"
                  ml="auto"
                  icon={<FaDoorOpen />}
                  colorScheme="teal"
                  isRound
                  onClick={updateDisabled}
                  isLoading={disableUserLoading}
                />
              </Tooltip>
            )
          ) : (
            ''
          )
        ) : (
          ''
        )}
      </Flex>
    </HStack>
  );
}
