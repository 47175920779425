import { Box, Text } from '@chakra-ui/react';

import Post from './index';

export default function PostsList({ posts }) {
  return (
    <Box px="4" align="left">
      {posts?.length === 0 ? (
        <Text align="center">No posts yet...</Text>
      ) : (
        posts?.map((post) => <Post key={post.id} post={post} />)
      )}
    </Box>
  );
}
