import { Box } from '@chakra-ui/react';
import { useInvites } from 'hooks/invites';

import Invite from '../invites/Invite';

export default function InviteList(params) {
  const { email } = params;
  const { invites, isLoading } = useInvites(email);

  if (!invites || isLoading) return 'Loading...';

  return (
    <Box maxWidth="800px">
      {invites.map((invite) => (
        <Invite key={invite.id} invite={invite} />
      ))}
    </Box>
  );
}
