import {
  Box,
  Button,
  Divider,
  Editable,
  EditablePreview,
  EditableTextarea,
  Flex,
  HStack,
  Select,
  SimpleGrid,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
  useDisclosure
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';

import PostsList from 'components/post/PostsList';
import { usePosts } from 'hooks/posts';
import Avatar from './Avatar';
import {
  useUserTags,
  useUpdateUser,
  useUser,
  useAddUserTag,
  useRemoveUserTag
} from 'hooks/users';
import { formatDistanceToNow } from 'date-fns';
import ChangeAvatar from './ChangeAvatar';
import { useAuth } from 'hooks/auth';
import {
  useConnectionsFromUser,
  useConnectionsToUser
} from 'hooks/connections';
import User from 'components/users/User';
import { ConnectionType } from 'lib/enums';
import { useTags } from 'hooks/tags';

export default function Profile() {
  const { id } = useParams();
  const { user: authUser, isLoading: authLoading } = useAuth();
  const { user, isLoading: userLoading } = useUser(id);
  const { posts, isLoading: postsLoading } = usePosts({ uid: id });
  const { updateUser, isLoading: updateUserLoading } = useUpdateUser(id);
  const {
    isOpen: isChangeAvatarOpen,
    onOpen: onChangeAvatarOpen,
    onClose: onChangeAvatarClose
  } = useDisclosure();
  const { connections: followingUsers, isLoading: followingUsersLoading } =
    useConnectionsFromUser(id, ConnectionType.Follow);
  const { connections: followedByUsers, isLoading: followedByUsersLoading } =
    useConnectionsToUser(id, ConnectionType.Follow);

  const { userTags, isLoading: userTagsLoading } = useUserTags(
    authUser ? authUser.id : ''
  );

  const { addTag } = useAddUserTag(authUser ? authUser.id : '');

  const { removeTag, isLoading: removeUserTagLoading } = useRemoveUserTag(
    authUser ? authUser.id : ''
  );

  const { tags, isLoading: tagsLoading } = useTags({});

  if (userLoading) return 'Loading...';

  // Get a filtered list of tags not already selected by the user, to use in the selection list
  let availableTags = [];
  if (!userTagsLoading && !tagsLoading) {
    let userTagIds = userTags.map((userTag) => userTag.tagId);
    for (let tag of tags) {
      if (!userTagIds.includes(tag.id)) {
        availableTags.push(tag);
      }
    }
  }

  function handleAddTag(tag) {
    let tagObj = JSON.parse(tag);
    addTag(tagObj);
  }

  function handleUpdateDescription(description) {
    updateUser({
      description
    });
    user.description = description;
  }

  return (
    <Stack spacing="5">
      <Flex p={['4', '6']} pos="relative" align="center">
        <Stack ml="10" align="center">
          <Avatar user={user} size="xl" />
          {!authLoading && authUser.id === user.id ? (
            <Button colorScheme="teal" onClick={onChangeAvatarOpen}>
              Change avatar
            </Button>
          ) : (
            ''
          )}
        </Stack>

        <ChangeAvatar
          isOpen={isChangeAvatarOpen}
          onClose={onChangeAvatarClose}
        />

        <Stack spacing="10">
          <HStack spacing="10" paddingLeft="5">
            <Text fontSize="2xl">@{user.username}</Text>
            <Text color="gray.700" fontSize={['md']}>
              Posts: {postsLoading ? '' : posts.length}
            </Text>
            <Text color="gray.700" fontSize={['md']}>
              Joined: {formatDistanceToNow(user.createDate)} ago
            </Text>
          </HStack>
          <Editable
            defaultValue={user.description || '¯\\_(ツ)_/¯'}
            isDisabled={updateUserLoading || authLoading || id !== authUser.id}
            paddingLeft="5"
            fontSize="xl"
            height="100"
            onSubmit={handleUpdateDescription}
          >
            <EditablePreview />
            <EditableTextarea fontSize="md" value={user.description} />
          </Editable>
        </Stack>
      </Flex>
      <Divider />

      <Tabs>
        <TabList>
          <Tab>Posts</Tab>
          <Tab>Following</Tab>
          <Tab>Followers</Tab>
          <Tab>Interests</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            {!posts | postsLoading ? (
              <Text>Loading...</Text>
            ) : (
              <PostsList posts={posts} />
            )}
          </TabPanel>
          <TabPanel>
            <Box>
              <SimpleGrid columns={[2, 3, 4]} spacing={[2, 3]} px="10px" py="6">
                {!followingUsersLoading &&
                followingUsers &&
                followingUsers.length > 0 ? (
                  followingUsers.map((following) => (
                    <User
                      uid={following.toUserId}
                      authUser={authUser}
                      isFollowed={true}
                    />
                  ))
                ) : (
                  <Text>Not following anyone yet</Text>
                )}
              </SimpleGrid>
            </Box>
          </TabPanel>
          <TabPanel>
            <Box>
              <SimpleGrid columns={[2, 3, 4]} spacing={[2, 3]} px="10px" py="6">
                {!followedByUsersLoading &&
                followedByUsers &&
                followedByUsers.length > 0 ? (
                  followedByUsers.map((follower) => {
                    return (
                      <User
                        uid={follower.fromUserId}
                        authUser={authUser}
                        isFollowed={false}
                        includeActions={false}
                      />
                    );
                  })
                ) : (
                  <Text fontSize={['md']}>No followers yet</Text>
                )}
              </SimpleGrid>
            </Box>
          </TabPanel>
          <TabPanel>
            <HStack>
              <Box width="400px">
                <HStack spacing={4}>
                  {userTags ? (
                    userTags.map((tag) => (
                      <Tag
                        size="md"
                        key={tag.text}
                        borderRadius="full"
                        variant="solid"
                        colorScheme="teal"
                      >
                        <TagLabel>{tag.text}</TagLabel>
                        {!removeUserTagLoading ? (
                          <TagCloseButton onClick={() => removeTag(tag)} />
                        ) : (
                          ''
                        )}
                      </Tag>
                    ))
                  ) : (
                    <Text fontSize={['md']}>No tags yet</Text>
                  )}
                </HStack>
              </Box>
              <Select
                variant="outline"
                placeholder="Find some tags"
                onChange={(e) => handleAddTag(e.target.value)}
                maxWidth="200"
              >
                {tagsLoading || !availableTags
                  ? 'Loading...'
                  : availableTags.map((tag) => (
                      <option
                        key={tag.id}
                        value={JSON.stringify({
                          tagId: tag.id,
                          text: tag.text
                        })}
                      >
                        {tag.text}
                      </option>
                    ))}
              </Select>
            </HStack>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Stack>
  );
}
