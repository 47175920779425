import { uuidv4 } from '@firebase/util';
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  orderBy,
  query,
  setDoc,
  updateDoc,
  where,
  writeBatch
} from 'firebase/firestore';
import { useState } from 'react';
import { useToast } from '@chakra-ui/react';

import { db } from 'lib/firebase';

import {
  useCollectionData,
  useDocumentData
} from 'react-firebase-hooks/firestore';
import { ConnectionType, PostType } from 'lib/enums';

export function usePost(id) {
  const q = doc(db, 'posts', id);
  const [post, isLoading, error] = useDocumentData(q);
  if (error) throw error;
  return { post, isLoading };
}

export function usePosts({ uid = null }) {
  const q = uid
    ? query(
        collection(db, 'posts'),
        where('uid', '==', uid),
        orderBy('createDate', 'desc')
      )
    : query(collection(db, 'posts'), orderBy('createDate', 'desc'));
  const [posts, isLoading, error] = useCollectionData(q);
  if (error) throw error;
  return { posts, isLoading };
}

export function useAddPost() {
  const [isLoading, setLoading] = useState(false);
  const toast = useToast();

  async function addPost(post) {
    setLoading(true);

    const id = post.id || uuidv4();
    let docRef = doc(db, 'posts', id);

    // !!! Note: For now, default all posts to 3D
    let type = PostType.ThreeDimensional;

    // !!! Note: For now, default visibility to Everyone unless otherwise specified
    let visibility = post.visibility || ConnectionType.Everyone;
    delete post.visibility;

    await setDoc(docRef, {
      ...post,
      id,
      views: 0,
      lengthSeconds: 0,
      ready: false,
      flagged: false,
      flaggedReason: '',
      type,
      visibility,
      createDate: Date.now()
    });
    setLoading(false);

    toast({
      title: 'Post added successfully',
      status: 'success',
      isClosable: true,
      postion: 'top',
      duration: 5000
    });
  }

  return { addPost, isLoading };
}

export function useUpdatePost() {
  const [isLoading, setLoading] = useState(false);

  async function updatePost(post) {
    setLoading(true);

    // !!! Note: For now, default visibility to Everyone unless otherwise specified
    let visibility = post.visibility || ConnectionType.Everyone;
    delete post.visibility;

    let docRef = doc(db, 'posts', post.id);
    await updateDoc(docRef, { ...post, visibility, updateDate: Date.now() });
    setLoading(false);
  }

  return { updatePost, isLoading };
}

export function useDeletePost(id) {
  const [isLoading, setLoading] = useState(false);
  const toast = useToast();

  async function deletePost() {
    const res = window.confirm('Are you sure you want to delete this post?');
    if (res) {
      setLoading(true);

      // First delete comments
      let q = query(collection(db, 'comments'), where('postId', '==', id));
      let querySnapshot = await getDocs(q);

      let batch = writeBatch(db);
      querySnapshot.docs.forEach((doc) => {
        batch.delete(doc.ref);
      });
      await batch.commit();

      // Secondly delete post tags
      q = query(collection(db, 'post_tags'), where('postId', '==', id));
      querySnapshot = await getDocs(q);

      batch = writeBatch(db);
      querySnapshot.docs.forEach((doc) => {
        batch.delete(doc.ref);
      });
      await batch.commit();

      // Now delete the post
      const docRef = doc(db, 'posts', id);
      await deleteDoc(docRef);

      toast({
        title: 'Post deleted',
        status: 'success',
        isClosable: true,
        postion: 'top',
        duration: 5000
      });

      setLoading(false);
    }
  }
  return { deletePost, isLoading };
}

export function useAddVideo() {
  const [isLoading, setLoading] = useState(false);
  const toast = useToast();

  async function addVideo(upload) {
    setLoading(true);

    const fileUid = uuidv4();

    const uploadUrl = upload.uploadUrl;
    const uploadToken = upload.uploadToken;
    const file = upload.file[0];

    const fileContent = await file.arrayBuffer();

    const hashBuffer = await crypto.subtle.digest('SHA-1', fileContent);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray
      .map((b) => b.toString(16).padStart(2, '0'))
      .join('');

    try {
      await fetch(uploadUrl, {
        method: 'POST',
        body: fileContent,
        mode: 'cors',
        headers: {
          'Content-Type': 'b2/x-auto',
          'X-Bz-File-Name': fileUid,
          Authorization: uploadToken,
          'X-Bz-Content-Sha1': hashHex
        }
      });

      setLoading(false);

      toast({
        title: 'Video uploaded successfully',
        status: 'success',
        isClosable: true,
        postion: 'top',
        duration: 5000
      });

      return fileUid;
    } catch (error) {
      toast({
        title: 'Video failed to upload',
        description: error.message,
        status: 'error',
        isClosable: true,
        postion: 'top',
        duration: 5000
      });
    }

    return null;
  }

  return { addVideo, isLoading };
}

export function usePostTags(postId) {
  const q = query(
    collection(db, 'post_tags'),
    where('postId', '==', postId),
    orderBy('text', 'asc')
  );
  const [postTags, isLoading, error] = useCollectionData(q);
  if (error) throw error;

  return { postTags, isLoading };
}

export function useAddPostTag(uid) {
  const [isLoading, setLoading] = useState(false);

  async function addTag(post, tag) {
    setLoading(true);

    const id = uuidv4();
    let docRef = doc(db, 'post_tags', id);
    await setDoc(docRef, {
      postId: post.id,
      tagId: tag.tagId,
      text: tag.text,
      id,
      uid,
      createDate: Date.now()
    });
    setLoading(false);
  }

  return { addTag, isLoading };
}

export function useRemovePostTag() {
  const [isLoading, setLoading] = useState(false);

  async function removeTag(posttag) {
    setLoading(true);

    // Delete the post tag
    const docRef = doc(db, 'post_tags', posttag.id);
    await deleteDoc(docRef);

    setLoading(false);
  }
  return { removeTag, isLoading };
}
