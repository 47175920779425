export const ConnectionType = {
  Everyone: 'everyone',
  Friend: 'friend',
  Follow: 'follow',
  Benefactor: 'benefactor'
};

export const PostType = {
  ThreeDimensional: '3d',
  ThreeSixty: '360'
};
