import {
  Box,
  Button,
  Center,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Link,
  Text
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { useRegister } from 'hooks/auth';
import { APP_DASHBOARD, LOGIN } from 'lib/routes';
import {
  emailValidate,
  passwordValidate,
  usernameValidate
} from 'utils/forms-validate';

export default function Register(props) {
  const { register: signup, isLoading } = useRegister();
  const { register, handleSubmit, reset, formState } = useForm();

  async function handleRegister(data) {
    const succeeded = await signup({
      username: data.username,
      email: data.email,
      password: data.password,
      redirectTo: APP_DASHBOARD
    });

    if (succeeded) reset();
  }

  return (
    <Center w="100%" h="100vh">
      <Box mx="1" maxW="md" p="9" borderWidth="1px" borderRadius="lg">
        <Heading mb="4" size="lg" textAlign="center">
          Sign Up!
        </Heading>

        <form onSubmit={handleSubmit(handleRegister)}>
          <FormControl isInvalid={formState.errors.username} py="2">
            <FormLabel>Username</FormLabel>
            <Input
              type="username"
              placeholder="chillbuddy"
              data-lpignore="true"
              {...register('username', usernameValidate)}
            />
            <FormErrorMessage>
              {formState.errors.email?.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={formState.errors.email} py="2">
            <FormLabel>Email</FormLabel>
            <Input
              type="username"
              placeholder="user@email.com"
              data-lpignore="true"
              {...register('email', emailValidate)}
            />
            <FormErrorMessage>
              {formState.errors.username?.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={formState.errors.password} py="2">
            <FormLabel>Password</FormLabel>
            <Input
              type="password"
              placeholder="abcd1234"
              data-lpignore="true"
              {...register('password', passwordValidate)}
            />
            <FormErrorMessage>
              {formState.errors.password?.message}
            </FormErrorMessage>
          </FormControl>

          <Button
            mt="4"
            type="submit"
            colorScheme="teal"
            size="md"
            width="full"
            isLoading={isLoading}
            loadingText="Signing Up"
          >
            Sign Up!
          </Button>
        </form>

        <Text fontSize="xlg" align="center" mt="6">
          Already have an account?{' '}
          <Link
            as={RouterLink}
            to={LOGIN}
            color="teal.800"
            fontWeight="medium"
            textDecor="underline"
            _hover={{ background: 'teal.100' }}
          >
            Login
          </Link>{' '}
          instead!
        </Text>
      </Box>
    </Center>
  );
}
