import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  setDoc,
  where
} from 'firebase/firestore';
import { uuidv4 } from '@firebase/util';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { db } from 'lib/firebase';
import { ConnectionType } from 'lib/enums';
import { useState } from 'react';
import { useToast } from '@chakra-ui/react';

export function useAddConnection(fromUser, toUser, connectionType) {
  const [isLoading, setLoading] = useState(false);
  const toast = useToast();

  async function addConnection() {
    setLoading(true);

    const id = uuidv4();
    const docRef = doc(db, 'connections', id);
    await setDoc(docRef, {
      fromUserId: fromUser.id,
      toUserId: toUser.id,
      connectionType,
      id,
      createDate: Date.now()
    });

    let toastMessage = '';
    if (connectionType === ConnectionType.Follow) {
      toastMessage = `Great! You're following ${toUser.username} 👍`;
    }

    toast({
      title: toastMessage,
      status: 'success',
      isClosable: true,
      postion: 'top',
      duration: 5000
    });
    setLoading(false);
  }

  return { addConnection, isLoading };
}

export function useDeleteConnection(fromUser, toUser, connectionType) {
  const [isLoading, setLoading] = useState(false);
  const toast = useToast();

  let confirmMessage = '';
  if (connectionType === ConnectionType.Follow) {
    confirmMessage = `Are you sure you want to stop following ${toUser.username}?`;
  }

  async function deleteConnection() {
    const res = window.confirm(confirmMessage);
    if (res) {
      setLoading(true);

      const q = query(
        collection(db, 'connections'),
        where('fromUserId', '==', fromUser.id),
        where('toUserId', '==', toUser.id),
        where('connectionType', '==', connectionType)
      );
      let connections = await getDocs(q);

      for (let connection of connections.docs) {
        const docRef = doc(db, 'connections', await connection.data()?.id);
        await deleteDoc(docRef);
      }

      let toastMessage = '';
      if (connectionType === ConnectionType.Follow) {
        toastMessage = `You've stopped following ${toUser.username}`;
      }

      toast({
        title: toastMessage,
        status: 'success',
        isClosable: true,
        postion: 'top',
        duration: 5000
      });

      setLoading(false);
    }
  }
  return { deleteConnection, isLoading };
}

export function useConnectionsFromUser(userId, connectionType) {
  const [connections, isLoading] = useCollectionData(
    query(
      collection(db, 'connections'),
      where('fromUserId', '==', userId),
      where('connectionType', '==', connectionType)
    )
  );

  return { connections, isLoading };
}

export function useConnectionsToUser(userId, connectionType) {
  const [connections, isLoading] = useCollectionData(
    query(
      collection(db, 'connections'),
      where('toUserId', '==', userId),
      where('connectionType', '==', connectionType)
    )
  );
  return { connections, isLoading };
}
