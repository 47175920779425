import {
  Button,
  FormControl,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay
} from '@chakra-ui/react';
import { useAuth } from 'hooks/auth';
import Avatar from './Avatar';
import { useUpdateAvatar } from 'hooks/users';

export default function ChangeAvatar({ isOpen, onClose }) {
  const { user, isLoading: authLoading } = useAuth();

  const {
    setFile,
    updateAvatar,
    isLoading: fileLoading,
    fileURL
  } = useUpdateAvatar(user?.id);

  function handleAvatarChange(event) {
    setFile(event.target.files[0]);
  }

  if (!user || authLoading || fileLoading) return 'Loading...';

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Change Avatar</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <HStack spacing="5">
            <Avatar user={user} overrideAvatar={fileURL} />
            <FormControl py="4">
              <input
                type="file"
                accept="image/*"
                onChange={handleAvatarChange}
              />
            </FormControl>
          </HStack>
          <Button
            loadingText="Uploading"
            w="full"
            my="6"
            colorScheme="teal"
            onClick={updateAvatar}
            isLoading={fileLoading}
          >
            Save
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
