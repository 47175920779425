import {
  Button,
  Flex,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { Link as RouterLink } from 'react-router-dom';

import {
  APP_DASHBOARD,
  APP_POST_NEW_START,
  APP_USERS,
  ADMIN_INVITES,
  ADMIN_TAGS
} from 'lib/routes';
import { useLogout } from 'hooks/auth';

export default function Navbar(props) {
  const { isAdmin } = props;
  const { logout, isLoading } = useLogout();

  let admin = '';
  if (isAdmin) {
    admin = (
      <Menu>
        <MenuButton as={Link} marginLeft="5" color="teal" fontWeight="bold">
          Admin
          <ChevronDownIcon />
        </MenuButton>
        <MenuList>
          <MenuItem color="teal" as={RouterLink} to={ADMIN_INVITES}>
            Invites
          </MenuItem>
          <MenuItem color="teal" as={RouterLink} to={ADMIN_TAGS}>
            Tags
          </MenuItem>
        </MenuList>
      </Menu>
    );
  }

  return (
    <Flex
      shadow="sm"
      pos="fixed"
      width="full"
      borderTop="6px solid"
      borderTopColor="teal.400"
      height="16"
      zIndex="3"
      bg="white"
    >
      <Image src="/logo.png" borderRadius="full" />
      <Flex px="8" w="full" align="center" maxW="1200px" justify="center">
        <Link color="teal" as={RouterLink} to={APP_DASHBOARD} fontWeight="bold">
          Home
        </Link>
        <Link
          paddingLeft="5"
          color="teal"
          as={RouterLink}
          to={APP_USERS}
          fontWeight="bold"
        >
          Search Users
        </Link>
        <Link
          paddingLeft="5"
          color="teal"
          as={RouterLink}
          to={APP_POST_NEW_START}
          fontWeight="bold"
        >
          New Post
        </Link>
        {admin}
        <Button
          ml="auto"
          colorScheme="teal"
          size="sm"
          onClick={logout}
          isLoading={isLoading}
        >
          Logout
        </Button>
      </Flex>
    </Flex>
  );
}
