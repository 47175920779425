import { Heading } from '@chakra-ui/react';
import PostsList from 'components/post/PostsList';
import { usePosts } from 'hooks/posts';

export default function Dashboard(props) {
  const { posts, isLoading: postsLoading } = usePosts({});
  return (
    <>
      <Heading size="lg" paddingTop="5" paddingBottom="3">
        where spatial meets social...
      </Heading>
      {!postsLoading ? <PostsList posts={posts} /> : ''}
    </>
  );
}
