export const emailValidate = {
  required: {
    value: true,
    message: 'Please enter your email address'
  },
  pattern: {
    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
    message: 'Email address is invalid'
  }
};

export const passwordValidate = {
  required: {
    value: true,
    message: 'Please enter your password'
  },
  minLength: {
    value: process.env.REACT_APP_MIN_PASSWORD_LENGTH,
    message: `Password must be at least ${process.env.REACT_APP_MIN_PASSWORD_LENGTH} characters long`
  }
};

export const usernameValidate = {
  required: {
    value: true,
    message: 'Please enter a username'
  },
  minLength: {
    value: process.env.REACT_APP_MIN_USERNAME_LENGTH,
    message: `Username must be at least ${process.env.REACT_APP_MIN_USERNAME_LENGTH} characters long`
  },
  pattern: {
    value: /^[A-Z0-9]+$/i,
    message: 'Username is invalid'
  }
};
