import { AvatarBadge, Avatar as ChakraAvatar, Tooltip } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

import { APP } from 'lib/routes';

export default function Avatar({ user, size = 'xl', overrideAvatar = null }) {
  return (
    <ChakraAvatar
      name={user.username}
      size={size}
      src={overrideAvatar || user.avatar}
      _hover={{ cursor: 'pointer', opacity: '0.8' }}
      as={Link}
      to={`${APP}/profile/${user.id}`}
    >
      {user.isDisabled ? (
        <Tooltip label="User disabled">
          <AvatarBadge borderColor="papayawhip" bg="tomato" boxSize="1.5em" />
        </Tooltip>
      ) : (
        ''
      )}
    </ChakraAvatar>
  );
}
