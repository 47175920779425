import { SimpleGrid } from '@chakra-ui/react';
import User from './User';
import { useUsers } from 'hooks/users';
import { useConnectionsFromUser } from 'hooks/connections';
import { ConnectionType } from 'lib/enums';

export default function UserList(params) {
  const { filterString, authUser } = params;
  const { users, isLoading } = useUsers({ filterString });

  const { connections: following, isLoading: followingLoading } =
    useConnectionsFromUser(
      authUser ? authUser.id : null,
      ConnectionType.Follow
    );

  if (!users || isLoading || followingLoading) return 'Loading...';

  let followingList = [];
  for (let follow of following) {
    followingList.push(follow.toUserId);
  }

  return (
    <SimpleGrid columns={[2, 3, 4]} spacing={[2, 3]} px="10px" py="6">
      {users?.map((user) => (
        <User
          isLoading={isLoading}
          key={user.id}
          user={user}
          authUser={authUser}
          isFollowed={followingList.includes(user.id)}
        />
      ))}
    </SimpleGrid>
  );
}
