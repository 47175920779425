import { useToast } from '@chakra-ui/react';
import { uuidv4 } from '@firebase/util';
import {
  collection,
  deleteDoc,
  doc,
  orderBy,
  query,
  setDoc,
  where
} from 'firebase/firestore';
import { db } from 'lib/firebase';
import { useState } from 'react';
import { useCollectionData } from 'react-firebase-hooks/firestore';

export function useAddComment({ postId, uid }) {
  const [isLoading, setLoading] = useState(false);
  const toast = useToast();

  async function addComment(comment) {
    setLoading(true);
    const id = uuidv4();

    const docRef = doc(db, 'comments', id);
    await setDoc(docRef, {
      text: comment,
      id,
      createDate: Date.now(),
      postId,
      uid
    });

    toast({
      title: 'Comment added',
      status: 'success',
      isClosable: true,
      postion: 'top',
      duration: 5000
    });
    setLoading(false);
  }

  return { addComment, isLoading };
}

export function useComments({ postId }) {
  const q = query(
    collection(db, 'comments'),
    where('postId', '==', postId),
    orderBy('createDate', 'asc')
  );
  const [comments, isLoading, error] = useCollectionData(q);
  if (error) throw error;
  return { comments, isLoading };
}

export function useDeleteComment(id) {
  const [isLoading, setLoading] = useState(false);
  const toast = useToast();

  async function deleteComment() {
    const res = window.confirm('Are you sure you want to delete this comment?');
    if (res) {
      setLoading(true);

      const docRef = doc(db, 'comments', id);
      await deleteDoc(docRef);

      toast({
        title: 'Comment deleted',
        status: 'success',
        isClosable: true,
        postion: 'top',
        duration: 5000
      });

      setLoading(false);
    }
  }
  return { deleteComment, isLoading };
}
