import { useUploads } from 'hooks/uploads';

import { Box, Button, HStack, Input, InputGroup } from '@chakra-ui/react';
import { useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useAddVideo } from 'hooks/posts';

function validateVideoFile(value) {
  if (value.length < 1) {
    return 'File is required';
  } else if (value.length > 1) {
    return 'Only one file can be included on a post';
  }

  for (const file of Array.from(value)) {
    const fsMb = file.size / (1024 * 1024);
    const MAX_FILE_SIZE = 200;
    if (fsMb > MAX_FILE_SIZE) {
      return 'Max file size 200mb';
    }
  }
  return true;
}

export default function VideoFileUpload(props) {
  const { register, handleSubmit, reset } = useForm();
  const { addVideo, isLoading: addingVideo } = useAddVideo();
  const inputRef = useRef(null);
  const handleClick = () => inputRef.current?.click();

  const { authuser: authUser, authtoken: authToken } = props;

  // Set up upload handler
  let onVideoUploaded = (videoId) => {
    console.log(videoId);
  };
  if (props.onVideoUploaded) {
    onVideoUploaded = props.onVideoUploaded;
  }

  let inputProps = { ...props };
  delete inputProps.onVideoUploaded;

  const {
    uploadUrl,
    uploadToken,
    isLoading: uploadLoading,
    error: uploadError
  } = useUploads(authToken);

  function handleUploadVideo(data) {
    async function upload(data) {
      let videoId = await addVideo({
        uid: authUser.id,
        ...data
      });

      reset();

      return onVideoUploaded(videoId);
    }

    upload(data);
  }

  if (uploadError) {
    return 'Error rendering upload component';
  } else {
    return (
      <Box maxW="800px" py="10" alignItems="left">
        <form onSubmit={handleSubmit(handleUploadVideo)}>
          <HStack justify="space-between">
            <InputGroup onClick={handleClick}>
              <Input
                type={'file'}
                multiple={false}
                accept={'video/*'}
                {...inputProps}
                {...register('file', {
                  required: true,
                  validate: validateVideoFile
                })}
              />
              {!uploadLoading ? (
                <>
                  <Input
                    type="hidden"
                    id="uploadUrl"
                    name="uploadUrl"
                    value={uploadUrl || ''}
                    {...register('uploadUrl', { required: true })}
                  />
                  <Input
                    type="hidden"
                    id="uploadToken"
                    name="uploadToken"
                    value={uploadToken || ''}
                    {...register('uploadToken', { required: true })}
                  />
                </>
              ) : (
                ''
              )}
            </InputGroup>
            <Button
              colorScheme="teal"
              type="submit"
              isLoading={uploadLoading || addingVideo}
              loadingText="Loading..."
            >
              Upload
            </Button>
          </HStack>
        </form>
      </Box>
    );
  }
}
