import { Box, Heading, Stack } from '@chakra-ui/react';
import { Navigate } from 'react-router-dom';
import VideoFileUpload from './VideoFileUpload';
import { useState } from 'react';
import { useAddPost } from 'hooks/posts';
import { useAuth } from 'hooks/auth';

export default function NewPostStart() {
  const [videoId, setVideoId] = useState('');
  const { addPost, isLoading: addPostLoading } = useAddPost();
  const { user: authUser, authToken, isLoading: authLoading } = useAuth();

  function handleVideoUploaded(videoId) {
    console.log(`here ${videoId}`);
    async function createPost(videoId) {
      console.log(`here 2 ${videoId}`);
      await addPost({
        uid: authUser.id,
        id: videoId,
        videoId
      });

      console.log(`here 3 ${videoId}`);

      // Set uploaded video ID into state so we can redirect to the page where
      // we'll finish creating the post
      setVideoId(videoId);
    }

    createPost(videoId);
  }

  if (authLoading || addPostLoading) return '';

  return (
    <Box maxW="600px" mx="auto" py="5">
      <Stack>
        <Heading size="md">Upload a spatial video</Heading>
        <VideoFileUpload
          onVideoUploaded={handleVideoUploaded}
          authuser={authUser}
          authtoken={authToken}
        />
        {videoId !== '' ? (
          <Navigate to={`/app/post/${videoId}`} replace={true} />
        ) : (
          ''
        )}
      </Stack>
    </Box>
  );
}
