import {
  Box,
  Button,
  HStack,
  Input,
  Select,
  Stack,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
  Textarea
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import TextareaAutosize from 'react-textarea-autosize';

import {
  useAddPostTag,
  usePost,
  usePostTags,
  useRemovePostTag,
  useUpdatePost
} from 'hooks/posts';
import { ConnectionType } from 'lib/enums';
import { Navigate, useParams } from 'react-router-dom';
import { useTags } from 'hooks/tags';
import { useAuth } from 'hooks/auth';
import { useState } from 'react';

export default function EditPost(params) {
  const { id } = useParams();
  const [submitted, setSubmitted] = useState(false);
  const { register, handleSubmit, reset } = useForm();
  const { updatePost, isLoading: updatePostLoading } = useUpdatePost();
  const { post, isLoading: postLoading } = usePost(id);
  const { user: authUser, isLoading: authUserLoading } = useAuth();
  const [visibility, setVisibility] = useState(null);

  const { postTags, isLoading: postTagsLoading } = usePostTags(id);

  const { addTag } = useAddPostTag(authUser ? authUser.id : '');

  const { removeTag, isLoading: removePostTagLoading } = useRemovePostTag();

  const { tags, isLoading: tagsLoading } = useTags({});

  function handleUpdatePost(data) {
    async function update() {
      await updatePost({
        id,
        description: data.description,
        title: data.title,
        visibility
      });
      reset();
      setSubmitted(true);
    }

    update();
  }

  // Putting in this logic so that even when we don't change the visibility selection, it retains it's value
  if (post && post.visibility && visibility == null) {
    setVisibility(post.visibility);
  }

  // Get a filtered list of tags not already selected by the user, to use in the selection list
  let availableTags = [];
  if (!postTagsLoading && !tagsLoading) {
    let postTagIds = postTags.map((postTag) => postTag.tagId);
    for (let tag of tags) {
      if (!postTagIds.includes(tag.id)) {
        availableTags.push(tag);
      }
    }
  }

  function handleAddTag(tag) {
    let tagObj = JSON.parse(tag);
    addTag(post, tagObj);
  }

  if (authUserLoading || postLoading) return '';

  return (
    <Box maxW="600px" mx="auto" py="5">
      <form onSubmit={handleSubmit(handleUpdatePost)}>
        <Stack spacing={3}>
          <Input
            size="lg"
            {...register('title', { required: true, value: post.title })}
            placeholder="Title"
          />

          <Textarea
            as={TextareaAutosize}
            resize="none"
            mt="5"
            placeholder={'Description'}
            minRows={2}
            {...register('description', {
              required: true,
              value: post.description
            })}
          />

          <HStack>
            <Text size="lg">Audience</Text>
            <Select
              onChange={(e) => {
                setVisibility(e.target.value);
              }}
            >
              {Object.entries(ConnectionType).map((kv) => (
                <option
                  key={kv[0]}
                  value={kv[1]}
                  selected={kv[1] === post.visibility}
                >
                  {kv[0]}
                </option>
              ))}
            </Select>
          </HStack>

          <HStack>
            <Box width="400px">
              <HStack spacing={4}>
                {postTags ? (
                  postTags.map((tag) => (
                    <Tag
                      size="md"
                      key={tag.text}
                      borderRadius="full"
                      variant="solid"
                      colorScheme="teal"
                    >
                      <TagLabel>{tag.text}</TagLabel>
                      {!removePostTagLoading ? (
                        <TagCloseButton onClick={() => removeTag(tag)} />
                      ) : (
                        ''
                      )}
                    </Tag>
                  ))
                ) : (
                  <Text fontSize={['md']}>No tags yet</Text>
                )}
              </HStack>
            </Box>
            <Select
              variant="outline"
              placeholder="Apply some tags"
              onChange={(e) => handleAddTag(e.target.value)}
              maxWidth="200"
            >
              {tagsLoading || !availableTags
                ? 'Loading...'
                : availableTags.map((tag) => (
                    <option
                      key={tag.id}
                      value={JSON.stringify({
                        tagId: tag.id,
                        text: tag.text
                      })}
                    >
                      {tag.text}
                    </option>
                  ))}
            </Select>
          </HStack>

          <Button
            colorScheme="teal"
            type="submit"
            isLoading={updatePostLoading}
            loadingText="Loading..."
          >
            Save
          </Button>
        </Stack>
      </form>

      {submitted ? <Navigate to={`/app/dashboard`} replace={true} /> : ''}
    </Box>
  );
}
