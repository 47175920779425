import { Box } from '@chakra-ui/react';
import { useTags } from 'hooks/tags';

import Tag from '../tags/Tag';

export default function TagList(params) {
  const { text } = params;
  const { tags, isLoading } = useTags({ text });

  if (!tags || isLoading) return 'Loading...';

  return (
    <Box maxWidth="800px">
      {tags.map((tag) => (
        <Tag key={tag.id} tag={tag} />
      ))}
    </Box>
  );
}
